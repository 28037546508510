$(document).ready(function(){
	const $homeSlider=$("#homeSlider");
	function playVideo(){
		$homeSlider.find('.owl-item.active .owl-video-play-icon').trigger('click');
		if(($homeSlider.outerWidth()/$homeSlider.outerHeight())<(16/9)){
			$homeSlider.find('iframe').css('width',(16*$homeSlider.find('iframe').outerHeight()/9)+'px');
		}else{
			$homeSlider.find('iframe').css('height',(9*$homeSlider.find('iframe').outerWidth()/16)+'px');
		}
		return false;
	}
	$homeSlider.owlCarousel({
		dots: false,
		loop: true,
		items: 1,
		nav:false,
		autoplay:true,
		video:true,
		autoplayTimeout:5000,
		animateIn:'fadeIn',
		animateOut:'fadeOut',
		onInitialized:function(event){
			setTimeout(playVideo, 500);
		}
	});
	$("#newsSlider,#eventsSlider").owlCarousel({
		dots: false,
		loop: false,
		items: 1,
		nav:true,
		navText:['<svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.385704 3.92794C0.190443 4.1232 0.190443 4.43978 0.385704 4.63505L3.56768 7.81703C3.76295 8.01229 4.07953 8.01229 4.27479 7.81703C4.47005 7.62176 4.47005 7.30518 4.27479 7.10992L1.44637 4.28149L4.27479 1.45307C4.47005 1.2578 4.47005 0.941221 4.27479 0.745959C4.07953 0.550697 3.76295 0.550696 3.56769 0.745959L0.385704 3.92794ZM18.2393 3.78149L0.739258 3.78149L0.739258 4.78149L18.2393 4.78149L18.2393 3.78149Z" fill="#050058"/></svg>','<svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9541 4.63505C18.1494 4.43979 18.1494 4.1232 17.9541 3.92794L14.7722 0.74596C14.5769 0.550698 14.2603 0.550698 14.0651 0.74596C13.8698 0.941223 13.8698 1.2578 14.0651 1.45307L16.8935 4.28149L14.0651 7.10992C13.8698 7.30518 13.8698 7.62177 14.0651 7.81703C14.2603 8.01229 14.5769 8.01229 14.7722 7.81703L17.9541 4.63505ZM0.100586 4.78149L17.6006 4.78149L17.6006 3.78149L0.100586 3.78149L0.100586 4.78149Z" fill="#050058"/></svg>']
	});


});